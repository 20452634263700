var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ele-divider",
      style: {
        backgroundColor: _vm.widget.properties.lineColor,
        height: _vm.widget.properties.lineHeight,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "el-divider__text",
          class: {
            "is-left": _vm.widget.properties.contentPosition == "left",
            "is-center": _vm.widget.properties.contentPosition == "center",
            "is-right": _vm.widget.properties.contentPosition == "right",
          },
          style: {
            color: _vm.widget.properties.fontColor,
            fontSize: _vm.widget.properties.fontSize + "px",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t(_vm.value + "")) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }