var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: "table-" + _vm.element.compCode,
      staticClass: "form-viewer-item-table-wrapper",
    },
    [
      _c(
        "div",
        { staticClass: "form-viewer-item-table-tools" },
        [
          _c(
            "el-button",
            {
              staticClass: "form-viewer-item-table-btn",
              attrs: {
                size: "mini",
                type: "primary",
                disabled: _vm.itemDisabled,
              },
              on: {
                click: function ($event) {
                  return _vm.addTableRow(_vm.element)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("lang_table_new_row")) + " ")]
          ),
          _c(
            "span",
            { staticClass: "form-viewer-item-table-tips" },
            [
              _vm.element.properties.minRowNum ||
              _vm.element.properties.minRowNum == 0
                ? [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lang_table_min_number_tips")) +
                        _vm._s(_vm.element.properties.minRowNum) +
                        _vm._s(_vm.$t("lang_table_strip")) +
                        _vm._s(_vm.$t("lang_table_data")) +
                        " "
                    ),
                  ]
                : _vm._e(),
              _vm.element.properties.maxRowNum ||
              _vm.element.properties.maxRowNum == 0
                ? [
                    _vm.element.properties.minRowNum ||
                    _vm.element.properties.minRowNum == 0
                      ? [_vm._v(" , ")]
                      : _vm._e(),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lang_table_max_number_tips")) +
                        _vm._s(_vm.element.properties.maxRowNum) +
                        _vm._s(_vm.$t("lang_table_strip")) +
                        _vm._s(_vm.$t("lang_table_data")) +
                        " "
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "form-viewer-item-table",
          attrs: {
            data: _vm.formWidgetsValueMap[_vm.element.compCode],
            "show-summary": _vm.element.properties.summary,
            "summary-method": function (val) {
              return _vm.handleTableSummary(val, _vm.element)
            },
            border: "",
          },
        },
        [
          _vm.element.properties.showIndex
            ? _c("el-table-column", {
                staticClass: "form-viewer-item-table-column",
                attrs: {
                  label: _vm.$t("lang_table_serial_number"),
                  type: "index",
                  width: "60",
                },
              })
            : _vm._e(),
          _vm._l(_vm.element.properties.components, function (item, itemIndex) {
            return _c("el-table-column", {
              key: itemIndex,
              staticClass: "form-viewer-item-table-column",
              attrs: { label: _vm.$t(item.compName), prop: item.compCode },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _vm.tableWidgetsRuleList[$index][item.compCode]
                              .required
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ])
                              : _vm._e(),
                            !_vm.tableWidgetsRuleList[$index][item.compCode] ||
                            !_vm.tableWidgetsRuleList[$index][item.compCode]
                              .hidden
                              ? _c("form-viewer-item", {
                                  staticClass: "form-viewer-item",
                                  attrs: {
                                    parentType: "table",
                                    widget: item,
                                    dataSource:
                                      _vm.tableWidgetsDataSourceList[$index][
                                        item.compCode
                                      ],
                                    triggerGroupId:
                                      _vm.tableWidgetsTriggerList[$index][
                                        item.compCode
                                      ],
                                    rules:
                                      _vm.tableWidgetsRuleList[$index][
                                        item.compCode
                                      ],
                                  },
                                  on: {
                                    trigger: function (val) {
                                      return _vm.handleTrigger(val, $index)
                                    },
                                    changeDataSource: function (val) {
                                      return _vm.handleChangeDataSource(
                                        val,
                                        $index
                                      )
                                    },
                                  },
                                  model: {
                                    value: row[item.compCode],
                                    callback: function ($$v) {
                                      _vm.$set(row, item.compCode, $$v)
                                    },
                                    expression: "row[item.compCode]",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("lang_table_operation"),
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          size: "small",
                          disabled: _vm.itemDisabled,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteTableRow(
                              _vm.formWidgetsValueMap[_vm.element.compCode],
                              scope.$index
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("lang_table_delete")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }