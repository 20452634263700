var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      style: {
        width: _vm.widget.properties.width + "%",
        height: _vm.widget.properties.height + "px",
      },
    },
    [
      _vm.widget.properties.showLabel
        ? _c(
            "div",
            {
              staticClass: "header",
              style: {
                textAlign: _vm.getLabelPosition,
                fontSize: _vm.widget.properties.labelFontSize + "px" || "20px",
                fontWeight:
                  _vm.widget.properties.labelFontBold == 1 ? "normal" : 550,
                color: _vm.widget.properties.labelFontColor,
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t(_vm.widget.compName)) + " "),
              _c(
                "div",
                { staticClass: "header-more", on: { click: _vm.clickMore } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("lang_more"))),
                    _c("i", { staticClass: "el-icon-d-arrow-right" }),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "list-con" },
        _vm._l(_vm.iconList, function (item, index) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.jumpUsuallyApplication(item)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "img-con",
                  style: {
                    width: _vm.widget.properties.iconWidth + "px",
                    height: _vm.widget.properties.iconHeight + "px",
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src:
                        _vm.previewUrl +
                        "?fileId=" +
                        item.icon +
                        "&token=" +
                        _vm.token,
                      alt: "",
                    },
                  }),
                ]
              ),
              _c("p", { staticClass: "item-title" }, [
                _vm._v(_vm._s(_vm.$t(item.name))),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }