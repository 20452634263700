var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ele-checkbox-container" },
    [
      _vm.widget.compCode
        ? _c(
            "el-checkbox-group",
            {
              class: {
                "ele-checkbox-configable": _vm._formDataSourceConfigurable,
              },
              attrs: {
                disabled: _vm.disabled,
                size: _vm.widget.properties.size,
              },
              on: { change: _vm.changeValue },
              model: {
                value: _vm.dataValueClone,
                callback: function ($$v) {
                  _vm.dataValueClone = $$v
                },
                expression: "dataValueClone",
              },
            },
            _vm._l(_vm.dataSource, function (item) {
              return _c(
                "el-checkbox",
                {
                  key: item.code,
                  style: {
                    width:
                      _vm.widget.properties.column > 0
                        ? "calc(" +
                          100 / _vm.widget.properties.column +
                          "% - 60px)"
                        : "",
                    marginLeft: "10px",
                  },
                  attrs: {
                    label: item.code,
                    border: _vm.widget.properties.border,
                  },
                },
                [_vm._v(" " + _vm._s(item.text) + " ")]
              )
            }),
            1
          )
        : _vm._e(),
      _vm._formDataSourceConfigurable
        ? _c(
            "div",
            {
              staticClass: "ele-checkbox-icon",
              on: { click: _vm.openFormDataDialog },
            },
            [_c("i", { staticClass: "el-icon-setting" })]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.formDataDialogVisible,
            width: "70%",
            top: "5vh",
            "append-to-body": "",
            title: _vm.$t("lang_data_manager"),
            "custom-class": "cus-formdata-dialog",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.formDataDialogVisible = $event
            },
          },
        },
        [
          _vm.formDataDialogVisible
            ? _c("form-data-manager", {
                attrs: { form: _vm.curForm, widgetId: _vm.widget.id },
              })
            : _vm._e(),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.formDataDialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_shut_down")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }