





































import { Component, Mixins } from 'vue-property-decorator';
import EleSelectorMinix from './selector';
@Component({
  name: 'EleRadio',
  components: { FormDataManager: () => import('@/components/form-data-manager/form-data-manager.vue') }
})
export default class EleRadio extends Mixins(EleSelectorMinix) {}
