var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ele-select-container" },
    [
      _c("treeselect", {
        class: { "ele-select-configable": _vm._formDataSourceConfigurable },
        attrs: {
          value: _vm.dataValue,
          limit: _vm.widget.dataSource.limit,
          options: _vm.dataSource,
          filterable: _vm.widget.properties.filterable,
          multiple: _vm.widget.properties.multiple,
          disableBranchNodes: _vm.widget.properties.disableBranchNodes,
          valueConsistsOf: _vm.widget.properties.valueConsistsOf,
          flat: _vm.widget.properties.flat,
          normalizer: _vm.normalizerHandler,
          placeholder: _vm.$t(_vm.widget.properties.placeholder),
          disabled: _vm.disabled,
          appendToBody: "",
          zIndex: "999999",
          noOptionsText: _vm.$t("lang_no_data"),
          noResultsText: _vm.$t("lang_no_search_results"),
        },
        on: { input: _vm.changeValue },
      }),
      _vm._formDataSourceConfigurable
        ? _c(
            "div",
            {
              staticClass: "ele-select-icon",
              on: { click: _vm.openFormDataDialog },
            },
            [_c("i", { staticClass: "el-icon-setting" })]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.formDataDialogVisible,
            width: "70%",
            top: "5vh",
            "append-to-body": "",
            title: _vm.$t("lang_data_manager"),
            "custom-class": "cus-formdata-dialog",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.formDataDialogVisible = $event
            },
          },
        },
        [
          _vm.formDataDialogVisible
            ? _c("form-data-manager", {
                attrs: { form: _vm.curForm, widgetId: _vm.widget.id },
              })
            : _vm._e(),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.formDataDialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_shut_down")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }