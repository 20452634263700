




















































import { Component, Prop, InjectReactive, Vue } from 'vue-property-decorator';
import FormViewerItem from './form-viewer-item.vue';
import { WidgetModel } from '@/models/form/WidgetModel';
import FormViewerTab from './form-viewer-tab.vue';
@Component({
  name: 'FormViewerGrid',
  components: {
    FormViewerItem,
    FormViewerTab
  }
})
export default class extends Vue {
  @Prop({ default: {} }) element!: WidgetModel; // 流程图中使用表单的地方 传过来的表单id
  @Prop({
    default: () => {
      return {};
    }
  })
  failedToPassCheckFieldMap: any; // 流程图中使用表单的地方 传过来的表单id

  @InjectReactive() flowId: any;
  @InjectReactive() formId: any;
  @InjectReactive() formDataId: any;
  @InjectReactive() formWidgetsValueMap!: any;
  @InjectReactive() formWidgetsRulesMap!: any;
  @InjectReactive() formWidgetsDataSourceMap: any; //表单项数据源
  @InjectReactive() formWidgetsTriggerMap!: any;
  @InjectReactive() showBorder!: boolean;
  @InjectReactive() showCellBorder!: boolean;
  @InjectReactive() marginWidth!: number;
  @InjectReactive() formWidth!: number;

  columnsLabelBorder: any = {};
  hiddenPlaceholderBlockFlg: boolean = false; // 表单元素是否显示占位块

  created() {
    this.buildLabelBorder();
    window.addEventListener('resize', this.buildLabelBorder);
  }

  //子组件input、select 是否显示提示语
  isShowPlaceholder(id) {
    if (Object.keys(this.failedToPassCheckFieldMap).length && this.failedToPassCheckFieldMap[id]) {
      if (!this.hiddenPlaceholderBlockFlg) {
        //当前栅格组件的 ‘子组件’ 中有必填项 且有一个以上校验未通过时 hiddenPlaceholderBlockFlg赋值为true
        this.hiddenPlaceholderBlockFlg = true;
      }
      return false;
    }
    return true;
  }

  /**
   * 处理触发器
   * @param {number} param
   * @returns {number}
   */
  handleTrigger({ eventType, widget, dataModel }) {
    this.$emit('trigger', { eventType, widget, dataModel });
  }

  /**
   * 改变组件数据源时
   */
  handleChangeDataSource({ element, dataSource }) {
    this.$emit('changeDataSource', { element, dataSource });
  }

  // 是否显示标签边框
  showLabelBorder(column) {
    let showLabelBorder = false;
    column.components.forEach(colItem => {
      if (!this.formWidgetsRulesMap[colItem.compCode] || !this.formWidgetsRulesMap[colItem.compCode].hidden) {
        if (colItem.properties.showLabel) {
          if (colItem.properties.cellBorder == true) {
            showLabelBorder = true;
          } else if ((colItem.properties.cellBorder == null || colItem.properties.cellBorder == undefined) && this.showCellBorder) {
            showLabelBorder = true;
          }
        }
      }
    });
    return showLabelBorder;
  }

  buildLabelBorder() {
    let result = {};
    let columns: any = this.element.properties.gridColumns;
    columns.forEach((column, index) => {
      let maxLabelWidth = 0;
      column.components.forEach(colItem => {
        if (!this.formWidgetsRulesMap[colItem.compCode] || !this.formWidgetsRulesMap[colItem.compCode].hidden) {
          if (colItem.properties.showLabel) {
            if (colItem.properties.cellBorder == true) {
              if (maxLabelWidth < colItem.properties.labelWidth) {
                maxLabelWidth = colItem.properties.labelWidth;
              }
            } else if ((colItem.properties.cellBorder == null || colItem.properties.cellBorder == undefined) && this.showCellBorder) {
              if (maxLabelWidth < colItem.properties.labelWidth) {
                maxLabelWidth = colItem.properties.labelWidth;
              }
            }
          }
        }
      });
      let borderPos = (this.formWidth * maxLabelWidth) / 1920 - 1;
      result[index] = borderPos + 'px';
    });
    this.columnsLabelBorder = result;
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.buildLabelBorder);
  }
}
