var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isDisplay
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
          },
          [
            _c(
              "el-upload",
              {
                attrs: {
                  action: "/river-fs/file/create",
                  headers: { Authorization: _vm.token },
                  multiple: false,
                  limit: 1,
                  name: "file",
                  "list-type": "picture-card",
                  "file-list": _vm.fileList,
                  "on-preview": _vm.handlePreview,
                  "on-remove": _vm.handleRemove,
                  "on-success": _vm.handleSuccess,
                  "on-error": _vm.handleError,
                  "on-exceed": _vm.handleExceed,
                  "before-upload": _vm.beforeAvatarUpload,
                },
              },
              [_c("i", { staticClass: "el-icon-plus" })]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  "custom-class": "ele-priview-dialog",
                  visible: _vm.dialogVisible,
                  title: _vm.previewFile.name,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "ele-priview-content" },
                  [
                    _c(
                      "el-image",
                      {
                        staticClass: "ele-priview-image",
                        attrs: {
                          fit: "fill",
                          src: _vm.previewFile.url,
                          lazy: "",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [_c("i", { staticClass: "el-icon-picture-outline" })]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        )
      : _vm.fileList[0]
      ? _c("img", {
          style: {
            width: _vm.imgStyle.width + "%",
            height: _vm.imgStyle.height + "px",
          },
          attrs: { src: _vm.fileList[0].url, alt: "" },
          on: { click: _vm.imgClick },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }