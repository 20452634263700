var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "block" },
      [
        _c(
          "el-carousel",
          {
            style: {
              width: _vm.widget.properties.width + "%",
            },
            attrs: {
              height: _vm.isDisplay
                ? _vm.widget.properties.height + "px"
                : "168px",
            },
          },
          _vm._l(_vm.carouselList, function (v, i) {
            return _c("el-carousel-item", { key: v.id }, [
              _c("img", {
                staticClass: "img",
                attrs: {
                  src:
                    _vm.previewUrl +
                    "?fileId=" +
                    v.imageId +
                    "&token=" +
                    _vm.token,
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.imgClick(v)
                  },
                },
              }),
              _c(
                "p",
                {
                  staticClass: "sub-title",
                  style: { color: _vm.widget.properties.fontColor },
                },
                [_vm._v(_vm._s(v.description))]
              ),
            ])
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }