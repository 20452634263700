var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      style: {
        width: _vm.widget.properties.width + "%",
        height: _vm.getHeight,
        bordeRadius: "0",
        border: _vm.showBorder,
      },
    },
    [
      _vm.widget.properties.showLabel
        ? _c(
            "div",
            {
              staticClass: "header",
              style: {
                textAlign: _vm.getLabelPosition,
                fontSize: _vm.widget.properties.labelFontSize + "px" || "20px",
                fontWeight:
                  _vm.widget.properties.labelFontBold == 1 ? "normal" : 550,
                color: _vm.widget.properties.labelFontColor,
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t(_vm.widget.compName)) + " "),
              _c(
                "div",
                { staticClass: "header-more", on: { click: _vm.clickMore } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("lang_more"))),
                    _c("i", { staticClass: "el-icon-d-arrow-right" }),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.isDisplay
        ? [
            _c(
              "div",
              { staticClass: "list-con" },
              _vm._l(_vm.pageInfoList, function (rowItem, rIndex) {
                return _c(
                  "el-row",
                  { key: _vm.getUuid() + rIndex },
                  _vm._l(_vm.colNumber, function (cIndex) {
                    return _c(
                      "el-col",
                      {
                        key: cIndex + _vm.getUuid(),
                        ref: "Row_" + rIndex + "_Col_" + (cIndex - 1),
                        refInFor: true,
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                        },
                        style: {
                          textAlign: _vm.getColLabelPosition,
                          width: rowItem[cIndex - 1].isShowTooltip
                            ? rowItem[cIndex - 1].width
                            : null,
                        },
                        attrs: { span: _vm.spanArr[cIndex - 1] },
                      },
                      [
                        rowItem[cIndex - 1].isShowTooltip
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: rowItem[cIndex - 1].value,
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "list-text",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickFileLink(rIndex)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(rowItem[cIndex - 1].value)
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "list-text",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickFileLink(rIndex)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      rowItem[cIndex - 1].value
                                        ? rowItem[cIndex - 1].value
                                        : " "
                                    )
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  }),
                  1
                )
              }),
              1
            ),
          ]
        : _c(
            "el-row",
            { staticClass: "list-con" },
            _vm._l(_vm.widget.properties.spans, function (item, index) {
              return _c(
                "el-col",
                {
                  key: index + _vm.getUuid(),
                  staticClass: "el-col-border",
                  attrs: { span: item },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.defaultFlag
                        ? "内容区域"
                        : _vm.columnConfigList[index].title
                    )
                  ),
                ]
              )
            }),
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }