









import { Component, Prop, Vue } from 'vue-property-decorator';
import DateUtil from 'global-ui/packages/utils/DateUtil';
@Component({
  name: 'EleText'
})
export default class EleText extends Vue {
  @Prop({ default: () => {} }) widget!: Record<string, any>;
  @Prop({ default: () => {} }) value!: any;

  styles: any = {};
  get _defaultValue() {
    if (this.widget.dataSource.defaultDataConfig && this.widget.dataSource.defaultDataConfig.type == 'FORMULA') {
      let formula = this.widget.dataSource.defaultDataConfig.formula;
      if (formula.label == 'date') {
        return DateUtil.format(new Date(formula.value));
      } else if (formula.label == 'input') {
        return formula.value;
      }
    }
    return '';
  }

  created() {
    let styles: any = {};
    let minHeight = this.widget.properties.minHeight;
    let textAlign = this.widget.properties.textAlign;
    let verticalAlign = this.widget.properties.verticalAlign;
    if (minHeight > 0) {
      styles.minHeight = minHeight + 'px';
      styles.display = 'flex';
    } else {
      styles.minHeight = 58 + 'px';
      styles.display = 'flex';
    }
    if (textAlign == 'left') {
      styles.justifyContent = 'flex-start';
    } else if (textAlign == 'center') {
      styles.justifyContent = 'center';
    } else if (textAlign == 'right') {
      styles.justifyContent = 'flex-end';
    }
    if (verticalAlign == 'top') {
      styles.alignItems = 'flex-start';
    } else if (verticalAlign == 'middle') {
      styles.alignItems = 'center';
    } else if (verticalAlign == 'bottom') {
      styles.alignItems = 'flex-end';
    }
    this.styles = styles;
  }
}
