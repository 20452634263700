var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      key: "card-" + _vm.element.compCode,
      staticClass: "form-viewer-item-card",
      style: { borderRightWidth: _vm._childLength ? "0px" : "1px" },
    },
    [
      _vm._l(
        _vm.element.properties.components,
        function (cardChild, cardChildIndex) {
          return [
            _vm.formWidgetsRulesMap[cardChild.compCode] &&
            !_vm.formWidgetsRulesMap[cardChild.compCode].hidden
              ? [
                  cardChild.compType === "row"
                    ? _c("form-viewer-grid", {
                        key: "card-grid-" + cardChild.compCode,
                        attrs: { element: cardChild },
                        on: { trigger: _vm.handleTrigger },
                      })
                    : cardChild.compType === "table"
                    ? _c("form-viewer-table", {
                        key: "table-" + cardChild.compCode,
                        style: {
                          borderTopWidth:
                            cardChildIndex == 0 || !_vm.showBorder
                              ? "0px"
                              : "1px",
                          borderLeftWidth: "0",
                        },
                        attrs: {
                          rules: _vm.formWidgetsRulesMap[_vm.element.compCode],
                          element: cardChild,
                          isStart: _vm.isStart,
                        },
                      })
                    : _c("form-viewer-item", {
                        key:
                          "card-" +
                          _vm.element.compCode +
                          "-child-" +
                          cardChild.compCode,
                        staticClass: "form-viewer-item",
                        style: {
                          borderTopWidth:
                            cardChildIndex == 0 ||
                            !_vm.showBorder ||
                            (cardChild.compType == "text" &&
                              cardChild.properties.border == false)
                              ? "0px"
                              : "1px",
                        },
                        attrs: {
                          widget: cardChild,
                          dataSource:
                            _vm.formWidgetsDataSourceMap[cardChild.compCode],
                          triggerGroupId:
                            _vm.formWidgetsTriggerMap[cardChild.compCode],
                          rules: _vm.formWidgetsRulesMap[cardChild.compCode],
                        },
                        on: {
                          trigger: _vm.handleTrigger,
                          changeDataSource: _vm.handleChangeDataSource,
                        },
                        model: {
                          value: _vm.formWidgetsValueMap[cardChild.compCode],
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formWidgetsValueMap,
                              cardChild.compCode,
                              $$v
                            )
                          },
                          expression: "formWidgetsValueMap[cardChild.compCode]",
                        },
                      }),
                ]
              : _vm._e(),
          ]
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }