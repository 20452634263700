

























































import { Component, Mixins } from 'vue-property-decorator';
import EleSelectorMinix from './selector';
import { getTaskHistoryList } from '@/api/flow-design/FlowApi';
@Component({
  name: 'EleSelect',
  components: { FormDataManager: () => import('@/components/form-data-manager/form-data-manager.vue') }
})
export default class EleSelect extends Mixins(EleSelectorMinix) {
  // 实现查看已办
  dataValueClick(runtimeId) {
    let routeUrl: any = '';
    getTaskHistoryList({ runtimeId: runtimeId }).then((res1: Record<string, any>) => {
      if (res1.data && res1.data.length > 0) {
        routeUrl = this.$router.resolve({ path: '/front2/done', query: { id: res1.data[0].id, seeType: 'form' } });
        // 跳转 默认打开新窗口
        window.open(routeUrl.href, '_blank');
      }
    });
  }
}
