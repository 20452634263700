<template>
  <div style="width: 100%; height: 100%" :id="id"></div>
</template>

<script>
import StoreIndex from '@/store/StoreIndex';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
export default {
  components: {},
  data() {
    return {
      content: '',
      id: ''
    };
  },
  props: {
    value: {},
    parentContent: {}
  },

  methods: {
    submitCont() {
      //将获取的内容复制给textarea绑定的值
      this.content = tinymce.editors[this.id].getContent();
      this.$emit('input', this.content);
    }
  },
  created() {
    this.id = SystemUtil.uuid();
    if (this.value) {
      this.content = this.value;
    }
  },
  mounted() {
    //tinymce在 index.html 使用<script>标签全局引入, 注：！！tinymce使用的是 js原生组件！！
    //@tinymce/tinymce-vue已下载 但没有使用（网上中文教程不全,英文很详细）

    tinymce.init({
      selector: '#' + this.id,
      //skin:'oxide-dark',
      // language_url: '../tinymce/langs/zh_CN.js', //汉化
      language: 'zh_CN',
      // 插件配置
      plugins:
        'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave indent2em autoresize',

      //顶部栏配置
      toolbar:
        'code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | \
    styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
    table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs',
      // 字体
      font_formats:
        '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',

      importcss_append: true,
      // 图片上传三个参数，图片数据，成功时的回调函数，失败时的回调函数
      images_upload_handler: (blobInfo, success, failure) => {
        const img = 'data:image/jpeg;base64,' + blobInfo.base64();
        success(img);
      },
      statusbar: false,
      toolbar_sticky: true,
      autosave_ask_before_unload: false,
      min_height: 450,
      init_instance_callback: editor => {
        // 该事件需要被移除
        editor.on('change', e => {
          // 变化时调用
          this.submitCont();
        });
        //
        editor.setContent(this.content);
      }
    });
  },
  beforeDestroy() {
    // 销毁  tinymce.remove() 不传参数为销毁全部
    tinymce.remove('#' + this.id);
  }
};
</script>
<style lang="scss" scoped>
#tinymce-editor {
  width: 100%;
  height: 100%;
  .tox-tinymce {
    width: 100%;
    height: 100%;
  }
}
</style>


