var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.properties.show
    ? _c("div", [_c("div", { domProps: { innerHTML: _vm._s(_vm.value) } })])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }