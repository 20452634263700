var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ele-select-container" },
    [
      _vm.widget.compCode &&
      _vm.widget.properties.isProcess == "Y" &&
      _vm.disabled &&
      _vm.dataValue &&
      !_vm.widget.properties.multiple
        ? _c("el-input", {
            staticClass: "readonly-select",
            attrs: { type: "text", readonly: "" },
            on: {
              focus: function ($event) {
                return _vm.dataValueClick(_vm.dataValue)
              },
            },
            model: {
              value: _vm.modelName,
              callback: function ($$v) {
                _vm.modelName = $$v
              },
              expression: "modelName",
            },
          })
        : _vm.widget.compCode &&
          _vm.widget.properties.isProcess == "Y" &&
          _vm.disabled &&
          _vm.dataValue &&
          _vm.widget.properties.multiple
        ? _c(
            "div",
            { staticClass: "multiple-select" },
            _vm._l(_vm.dataValueList, function (dItem, dIndex) {
              return _c(
                "el-tag",
                {
                  key: dIndex,
                  staticClass: "multiple-tag",
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      return _vm.dataValueClick(dItem.code)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(dItem.text) + " ")]
              )
            }),
            1
          )
        : _c(
            "div",
            { staticClass: "width100" },
            [
              _vm.widget.compCode
                ? _c(
                    "el-select",
                    {
                      class: {
                        "ele-select-configable":
                          _vm._formDataSourceConfigurable,
                      },
                      attrs: {
                        value: _vm.dataValue,
                        placeholder: _vm.placeholder
                          ? _vm.$t(_vm.widget.properties.placeholder)
                          : null,
                        disabled: _vm.disabled,
                        filterable: _vm.widget.properties.filterable,
                        multiple: _vm.widget.properties.multiple,
                        clearable: "",
                      },
                      on: { change: _vm.changeValue },
                    },
                    _vm._l(_vm.dataSources, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.text, value: item.code },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
      _vm._formDataSourceConfigurable
        ? _c(
            "div",
            {
              staticClass: "ele-select-icon",
              on: { click: _vm.openFormDataDialog },
            },
            [_c("i", { staticClass: "el-icon-setting" })]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.formDataDialogVisible,
            width: "70%",
            top: "5vh",
            "append-to-body": "",
            title: _vm.$t("lang_data_manager"),
            "custom-class": "cus-formdata-dialog",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.formDataDialogVisible = $event
            },
          },
        },
        [
          _vm.formDataDialogVisible
            ? _c("form-data-manager", {
                attrs: { form: _vm.curForm, widgetId: _vm.widget.id },
              })
            : _vm._e(),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.formDataDialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_shut_down")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }