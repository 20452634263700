<template>
  <div>
    <!-- 预览、最终生成页面 -->
    <div class="block">
      <el-carousel
        :height="isDisplay ? widget.properties.height + 'px' : '168px'"
        :style="{
          width: widget.properties.width + '%'
        }"
      >
        <el-carousel-item v-for="(v, i) in carouselList" :key="v.id">
          <img @click="imgClick(v)" class="img" :src="previewUrl + '?fileId=' + v.imageId + '&token=' + token" alt="" />
          <p class="sub-title" :style="{ color: widget.properties.fontColor }">{{ v.description }}</p>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
export default {
  name: 'EleCarousel',

  data() {
    return {
      token: '',
      loading: false,
      formId: '',
      previewUrl: '',
      // 图片组件上传地址
      uploadUrl: '/river-fs/file/create',
      // 轮播图列表
      carouselList: [],
    };
  },
  props: {
    widget: {
      type: Object,
      default() {
        return {};
      }
    },
    isDisplay: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.token = StorageUtil.getSessionStorage('token');
    this.previewUrl = this.widget.properties.previewUrl || '/river-fs/file/download';
    this.formId = this.$route.query.formId;
    if (this.widget.properties?.data) {
      this.carouselList = this.widget.properties.data;
    }
  },
  methods: {
    imgClick(val) {
      if (!val.linkUrl) return;
      const properties = this.widget.properties;
      if (properties.target == '_blank') {
        window.open(val.linkUrl, '_blank');
      } else {
        this.$router.push({ path: val.linkUrl });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.img {
  display: block;
  width: 100%;
  height: 100%;
}
.sub-title {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
/deep/.ele-priview-dialog {
  .el-dialog__header {
    text-align: center;
    font-size: var(--rootFontSize);
    border-bottom: 1px solid #ddd;
  }
  .ele-priview-content {
    height: 500px;
    width: 100%;
    .ele-priview-image {
      width: 100%;
      height: 100%;
      .image-slot {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eee;
        i {
          font-size: 120px;
        }
      }
    }
  }
}
/deep/ .el-carousel__indicators--horizontal {
  height: 28px;
}
/deep/ .el-carousel--horizontal {
  overflow-y: hidden;
}
</style>
