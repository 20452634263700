var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.widget.properties.show
    ? _c("div", { staticClass: "cus-ele-text", style: _vm.styles }, [
        _c(
          "p",
          {
            staticStyle: {
              "margin-block-start": "0em",
              "margin-block-end": "0em",
              margin: "12px",
            },
            style: { fontSize: _vm.widget.properties.fontSize + "px" },
          },
          [_vm._v(" " + _vm._s(_vm.value || _vm._defaultValue) + " ")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }