
























import { Component, Prop, Vue, InjectReactive } from 'vue-property-decorator';
import { WidgetModel } from '@/models/form/WidgetModel';
import FormViewerGrid from './form-viewer-grid.vue';
import FormViewerTable from './form-viewer-table.vue';
import FormViewerItem from './form-viewer-item.vue';

@Component({
  name: 'FormViewerCard',
  components: {
    FormViewerItem,
    FormViewerTable,
    FormViewerGrid
  }
})
export default class extends Vue {
  @Prop({ default: {} }) element!: WidgetModel; // 流程图中使用表单的地方 传过来的表单id
  @Prop({ default: false }) isStart?: boolean;

  @InjectReactive() flowId: any;
  @InjectReactive() formId: any;
  @InjectReactive() formDataId: any;

  @InjectReactive() formWidgetsValueMap!: any;
  @InjectReactive() formWidgetsRulesMap!: any;
  @InjectReactive() formWidgetsDataSourceMap: any; //表单项数据源
  @InjectReactive() formWidgetsTriggerMap: any; // 当前页面的表单校验规则
  @InjectReactive() showBorder!: boolean;

  get _childLength() {
    return this.element.properties.components?.length || 0;
  }
  /**
   * 处理触发器
   * @param {number} param
   * @returns {number}
   */
  handleTrigger({ eventType, widget, dataModel }) {
    this.$emit('trigger', { eventType, widget, dataModel });
  }

  /**
   * 改变组件数据源时
   */
  handleChangeDataSource({ element, dataSource }) {
    this.$emit('changeDataSource', { element, dataSource });
  }
}
