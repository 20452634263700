























import { Component, Prop, Vue } from 'vue-property-decorator';
import FlowCenter from '@/views/home/components/flowCenter.vue';
@Component({
  name: 'EleTasks',
  components: {
    FlowCenter
  }
})
export default class EleTasks extends Vue {
  @Prop({
    default: () => {
      return {};
    }
  })
  properties!: any;

  @Prop({
    default: false
  })
  isDisplay: boolean;

  activeName: string = 'wait';

  changeActiveName(type: string): void {
    this.activeName = type;
  }

  // 点击更多 路由跳转
  clickMore() {
    let url: string = '';
    switch (this.activeName) {
      case 'wait':
        url = '/waitListView';
        break;
      case 'start':
        url = '/startedListView';
        break;
      case 'done':
        url = '/doneListView';
        break;
      case 'finished':
        url = '/endView';
        break;
      case 'carbon':
        url = '/ccView';
        break;
      case 'transfer':
        url = '/delegateView';
        break;
      case 'possibility':
        url = '/possibilityListView';
        break;
    }
    if (!url) return;
    let routeUrl = this.$router.resolve({
      path: url
    });
    window.open(routeUrl.href, '_blank');
  }
}
