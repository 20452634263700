







import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  name: 'EleHtml',
  components: {}
})
export default class extends Vue {
  @Prop({ default: () => {} }) private properties!: Record<string, any>;
  @Prop() value!: string;
  token: string = '';
  mounted() {
    this.token = StorageUtil.getSessionStorage('token');
    this.$nextTick(() => {
      let grid = this.$el.getElementsByClassName('node-grid');
      if (grid.length) {
        for (let i of grid) {
          this.transMutiple(i);
        }
      }
      let link = this.$el.querySelectorAll('.node-link-image');
      if (link.length) {
        link.forEach(a => {
          if (!a.children.length) {
            let fileId = a.innerHTML;
            if (!fileId) return;
            let img = document.createElement('img');
            img.src = '/river-fs/file/view?fileId=' + fileId + '&token=' + this.token;
            img.style.verticalAlign = 'middle';
            // img.style.height = '100px';
            img.style.width = '100%';
            a.replaceWith(img);
          } else {
            let span = a.querySelector('span');
            let label: HTMLSpanElement | null = null;
            if (span) {
              label = <HTMLSpanElement>span.cloneNode(true);
              a.removeChild(span);
            }
            let fileId = a.textContent;
            if (!fileId) return;
            let img = document.createElement('img');
            img.src = '/river-fs/file/view?fileId=' + fileId + '&token=' + this.token;
            img.style.verticalAlign = 'middle';
            // img.style.height = '100px';
            img.style.width = '100%';
            a.textContent = null;
            if (label) {
              a.appendChild(label);
            }
            a.appendChild(img);
          }
        });
      }
    });
  }
  transMutiple(dom: Element) {
    let arr: Element[] = [];
    let leng = dom.children.length;
    if (leng % 3 === 0) {
      leng /= 3;
      for (let i = 0; i < leng; i++) {
        let div = document.createElement('div');
        let node1 = dom.children[i].cloneNode(true);
        let node2: HTMLElement = <HTMLElement>dom.children[i + leng].cloneNode(true);
        let node3: HTMLElement = <HTMLElement>dom.children[i + leng * 2].cloneNode(true);
        div.appendChild(node1);
        div.appendChild(node2);
        div.appendChild(node3);
        if (node2.className === 'node-link-image') {
          node3.style.height = '40px';
          node3.style.lineHeight = '40px';
        }
        arr.push(div);
      }
    }
    dom.replaceChildren(...arr);
  }
}
