
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
@Component({
  name: 'EleEditor',
  components: {
    quillEditor
  }
})
export default class extends Vue {
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: '' }) value!: string;
  @Prop({
    default: () => {
      return {
        validateMaxText: false,
        max: 0
      };
    }
  })
  properties!: Record<string, any>;
  content: string = '';
  currentLength: number = 0;
  defaultMaxLength: number = 2000;
  editorOption: Record<string, any> = {};

  get editor() {
    // return this.$refs.myQuillEditor.quill;
    return (this.$refs.myQuillEditor as Vue & { quill: Function }).quill;
  }
  get maxTextLength() {
    let len = this.defaultMaxLength;
    if (this.properties.max >= 1) {
      len = this.properties.max;
    }
    return len;
  }
  get warnTextLength() {
    const warn = this.properties.validateMaxText && this.currentLength > this.maxTextLength;
    return warn;
  }
  created() {
    this.content = this.value;
  }
  onEditorBlur(editor) {} // 失去焦点事件
  onEditorFocus(v) {} // 获得焦点事件
  onEditorChange(v) {
    this.currentLength = v.text.length - 1;
    this.$emit('input', this.content);
  } // 内容改变事件

  @Watch('value')
  onValueChange(newVal) {
    this.content = newVal;
  }
}
