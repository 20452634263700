<template>
  <div>
    <!-- 编辑 上传图片使用 -->
    <div v-loading="loading" v-if="!isDisplay">
      <el-upload
        action="/river-fs/file/create"
        :headers="{ Authorization: token }"
        :multiple="false"
        :limit="1"
        name="file"
        list-type="picture-card"
        :file-list="fileList"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :on-error="handleError"
        :on-exceed="handleExceed"
        :before-upload="beforeAvatarUpload"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog custom-class="ele-priview-dialog" :visible.sync="dialogVisible" :title="previewFile.name">
        <div class="ele-priview-content">
          <el-image class="ele-priview-image" fit="fill" :src="previewFile.url" lazy>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
      </el-dialog>
    </div>
    <!-- 预览、最终生成页面 -->
    <img
      v-else-if="fileList[0]"
      :src="fileList[0].url"
      alt=""
      :style="{
        width: imgStyle.width + '%',
        height: imgStyle.height + 'px'
      }"
      @click="imgClick"
    />
  </div>
</template>

<script>
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import FileApi from '@/api/system/FileApi';
import StoreIndex from '@/store/StoreIndex';
export default {
  name: 'EleOccupy',

  data() {
    return {
      token: '',
      loading: false,
      uploadCount: 0,
      dialogImageUrl: null,
      isValueChange: false,
      dialogVisible: false,
      previewFile: {},
      fileList: [],
      result: [],
      formId: '',
      fileId: '',
      imgStyle: {}
    };
  },
  props: {
    widget: {
      type: Object,
      default() {
        return {};
      }
    },
    isDisplay: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.token = StorageUtil.getSessionStorage('token');
    this.formId = this.$route.query.formId;

    this.getFileList([this.widget.properties.fileId]);
    this.initImgStyle(this.widget.properties);
  },
  methods: {
    //获取文件列表
    getFileList(fileIds) {
      this.loading = true;
      if (fileIds && fileIds.length) {
        FileApi.list({ ids: fileIds }).then(res => {
          if (res.code == '1' && res.data) {
            this.fileList = res.data.map(item => {
              let previewUrl = this.widget.properties.previewUrl || '/river-fs/file/download';
              previewUrl = previewUrl + '?fileId=' + item.id + '&token=' + this.token;
              return {
                id: item.id,
                name: item.fileName,
                url: previewUrl,
                response: { data: item }
              };
            });
            this.loading = false;
          }
        });
      } else {
        this.fileList = [];
        this.loading = false;
      }
    },
    //上传成功后
    handleSuccess(res, file, fileList) {
      this.uploadCount--;
      if (this.uploadCount === 0) {
        this.loading = false;
        this.updateValue(fileList);
      }
    },
    //上传之前
    beforeAvatarUpload(file) {
      if (
        this.widget.properties.accept &&
        this.widget.properties.accept.length &&
        !this.widget.properties.accept.includes(file.name.split('.')[1])
      ) {
        this.$message.warning(this.$t('lang_file_format_error'));
        return false;
      }
      this.uploadCount++;
      return true;
    },

    //上传失败
    handleError(err, file, fileList) {
      this.loading = false;
      this.$message.error(this.$t('lang_upload_fail'));
    },

    // 文件超出数量
    handleExceed() {
      this.loading = false;
      this.$message.error(this.$t('lang_file_limit'));
    },

    handleRemove(file, fileList) {
      this.updateValue(fileList);
    },
    handlePreview(file) {
      this.previewFile = file;
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //更新vuex的值
    updateValue(fileList) {
      this.isValueChange = true;
      let result = fileList.map(item => item.response.data);
      let widget = this.widget;
      widget.properties.fileId = result[0].fileId;
      const data = {
        data: widget,
        formId: this.formId
      };
      StoreIndex.commit('SET_FORM_CONFIG_IMG_FILE_ID', data);
      this.$emit('success', result);
    },

    initImgStyle(data) {
      this.imgStyle = data;
    },
    imgClick() {
      const properties = this.widget.properties;
      if (!properties.linkUrl) return;
      if (properties.target == '_blank') {
        window.open(properties.linkUrl, '_blank');
      } else {
        this.$router.push({ path: properties.linkUrl });
      }
    }
  }
};
</script>

<style scoped lang="scss">
/deep/.ele-priview-dialog {
  .el-dialog__header {
    text-align: center;
    font-size: var(--rootFontSize);
    border-bottom: 1px solid #ddd;
  }
  .ele-priview-content {
    height: 500px;
    width: 100%;
    .ele-priview-image {
      width: 100%;
      height: 100%;
      .image-slot {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eee;
        i {
          font-size: 120px;
        }
      }
    }
  }
}
</style>
