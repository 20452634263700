<template>
  <div v-loading="loading">
    <el-upload
      :action="widget.properties.action"
      :headers="{ Authorization: token }"
      :multiple="widget.properties.multiple"
      :disabled="disabled"
      :limit="widget.properties.limit"
      name="file"
      list-type="picture-card"
      :file-list="fileList"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-exceed="handleExceed"
      :before-upload="beforeAvatarUpload"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog custom-class="ele-priview-dialog" :visible.sync="dialogVisible" :title="previewFile.name">
      <div class="ele-priview-content">
        <el-image class="ele-priview-image" fit="fill" :src="previewFile.url1" lazy>
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import FileApi from '@/api/system/FileApi';

export default {
  name: 'EleImage',
  data() {
    return {
      token: '',
      loading: false,
      uploadCount: 0,
      dialogImageUrl: null,
      isValueChange: false,
      dialogVisible: false,
      previewFile: {},
      fileList: [],
      result: []
    };
  },
  props: {
    widget: {
      type: Object,
      default() {
        return {};
      }
    },
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(curVal, oldVal) {
      if (this.isValueChange) {
        this.isValueChange = false;
      } else {
        this.getFileList(curVal);
      }
    }
  },
  created() {
    this.token = StorageUtil.getSessionStorage('token');
    this.getFileList(this.value);
  },
  methods: {
    //获取文件列表
    getFileList(fileIds) {
      this.loading = true;
      if (fileIds && fileIds.length) {
        FileApi.list({ ids: fileIds }).then(res => {
          if (res.code == '1' && res.data) {
            this.fileList = res.data.map(item => {
              let previewUrl = this.widget.properties.previewUrl || '/river-fs/file/download';
              previewUrl = previewUrl + '?fileId=' + item.id + '&token=' + this.token;
              return {
                id: item.id,
                name: item.fileName,
                url: previewUrl,
                response: { data: item }
              };
            });
            this.loading = false;
          }
        });
      } else {
        this.fileList = [];
        this.loading = false;
      }
    },
    //上传成功后
    handleSuccess(res, file, fileList) {
      // let previewUrl =
      //   this.widget.properties.previewUrl || "/river-fs/file/download";
      // previewUrl =
      //   previewUrl + "?fileId=" + res.data.id + "&token=" + this.token;
      // this.fileList.push({
      //   id: res.data.id,
      //   name: res.data.fileName,
      //   url: previewUrl,
      // });
      this.uploadCount--;
      if (this.uploadCount === 0) {
        this.loading = false;
        this.updateValue(fileList);
      }
    },
    //上传之前
    beforeAvatarUpload(file) {
      if (
        this.widget.properties.accept &&
        this.widget.properties.accept.length &&
        !this.widget.properties.accept.includes(file.name.split('.')[1])
      ) {
        this.$message.warning(this.$t('lang_file_format_error'));
        return false;
      }
      this.uploadCount++;
      return true;
    },

    //上传失败
    handleError(err, file, fileList) {
      this.loading = false;
      this.$message.error(this.$t('lang_upload_fail'));
    },

    // 文件超出数量
    handleExceed() {
      this.loading = false;
      this.$message.error(this.$t('lang_file_limit'));
    },

    handleRemove(file, fileList) {
      this.updateValue(fileList);
    },
    handlePreview(file) {
      this.previewFile = file;
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //更新v-mode的值
    updateValue(fileList) {
      this.isValueChange = true;
      let result = fileList.map(item => item.response.data);
      let value = result.map(item => item.id);
      this.$emit('input', value);
      this.$emit('success', result);
    }
  }
};
</script>

<style scoped lang="scss">
/deep/.ele-priview-dialog {
  .el-dialog__header {
    text-align: center;
    font-size: var(--rootFontSize);
    border-bottom: 1px solid #ddd;
  }
  .ele-priview-content {
    height: 500px;
    width: 100%;
    .ele-priview-image {
      width: 100%;
      height: 100%;
      .image-slot {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eee;
        i {
          font-size: 120px;
        }
      }
    }
  }
}
</style>
