<template>
  <el-card
    :style="{
      width: widget.properties.width + '%',
      height: getHeight,
      bordeRadius: '0',
      border: showBorder
    }"
  >
    <div
      v-if="widget.properties.showLabel"
      :style="{
        textAlign: getLabelPosition,
        fontSize: widget.properties.labelFontSize + 'px' || '20px',
        fontWeight: widget.properties.labelFontBold == 1 ? 'normal' : 550,
        color: widget.properties.labelFontColor
      }"
      class="header"
    >
      {{ $t(widget.compName) }}
      <div @click="clickMore" class="header-more">
        <span>{{ $t('lang_more') }}<i class="el-icon-d-arrow-right"></i></span>
      </div>
    </div>
    <template v-if="isDisplay">
      <div class="list-con">
        <el-row v-for="(rowItem, rIndex) in pageInfoList" :key="getUuid() + rIndex">
          <el-col
            :style="{ textAlign: getColLabelPosition, width: rowItem[cIndex - 1].isShowTooltip ? rowItem[cIndex - 1].width : null }"
            style="overflow: hidden; text-overflow: ellipsis"
            v-for="cIndex in colNumber"
            :key="cIndex + getUuid()"
            :span="spanArr[cIndex - 1]"
            :ref="`Row_${rIndex}_Col_${cIndex - 1}`"
          >
            <!-- 显示tooltip 文字省略时-->
            <el-tooltip v-if="rowItem[cIndex - 1].isShowTooltip" :content="rowItem[cIndex - 1].value" placement="top">
              <span @click="clickFileLink(rIndex)" class="list-text"> {{ rowItem[cIndex - 1].value }}</span>
            </el-tooltip>
            <!-- 不显示tooltip -->
            <span v-else @click="clickFileLink(rIndex)" class="list-text">
              {{ rowItem[cIndex - 1].value ? rowItem[cIndex - 1].value : ' ' }}</span
            >
          </el-col>
        </el-row>
      </div>
    </template>

    <el-row class="list-con" v-else>
      <el-col class="el-col-border" v-for="(item, index) in widget.properties.spans" :key="index + getUuid()" :span="item">{{
        defaultFlag ? '内容区域' : columnConfigList[index].title
      }}</el-col>
    </el-row>
  </el-card>
</template>

<script>
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import _ from 'lodash';
export default {
  name: 'NormalList',

  data() {
    return {
      token: '',
      loading: false,
      formId: '',
      colNumber: 3,
      spanArr: [8, 8, 8],
      columnConfigList: [],
      defaultFlag: true,
      pageInfoList: [],
      isShowTooltip: [],
      limitDataRows: 10,
      showBorder: 'normal',
      target: '_blank',

      moreLinkUrl: '', //更多跳转地址
      detailLinkUrl: '' //详情跳转地址
    };
  },
  props: {
    widget: {
      type: Object,
      default() {
        return {};
      }
    },

    /******************************************************************
     *用来判断组件的挂载位置 false--编辑中  true--展示中
     ******************************************************************/
    isDisplay: {
      type: Boolean,
      default: false
    },
    pageInfo: {
      default: () => {}
    }
  },
  computed: {
    getColLabelPosition() {
      let properties = this.widget.properties;
      if (properties.valuePosition == 'center') return 'center';
      if (properties.valuePosition == 'right') return 'right';
      return 'left';
    },
    getLabelPosition() {
      let properties = this.widget.properties;
      if (properties.labelPosition == 'center') return 'center';
      if (properties.labelPosition == 'right') return 'right';
      return 'left';
    },
    getHeight() {
      let properties = this.widget.properties;
      if (this.widget.parentComponents?.compType == 'tab') {
        return '100%';
      }
      return properties.height + 'px';
    }
  },

  created() {
    this.token = StorageUtil.getSessionStorage('token');
    this.formId = this.$route.query.formId;
    // 处理数据
    this.initData(this.widget);
    // 当组件为编辑时
    if (!this.isDisplay) this.drawInit();

    if (this.widget.parentComponents?.compType == 'tab') {
      this.showBorder = 'none';
    }
  },
  mounted() {
    this.adjustmentTextWidth();
    // 更新视图 层级较深的元素状态 更新时 VUE有时无法检测到
    this.$forceUpdate();
  },

  methods: {
    /**
     * 调整文本显示长度
     */
    adjustmentTextWidth() {
      // 文字超长 显示Tooltip
      Object.keys(this.$refs).map(keys => {
        // 获取到当前el-col的DOM
        const elCol = this.$refs[keys][0] ? this.$refs[keys][0].$el : undefined;
        if (elCol) {
          // 获取el-col的宽度
          const colWidth = elCol.offsetWidth;
          const rowWidth = elCol.parentNode.offsetWidth;
          // 获取el-col下的span元素
          const span = elCol.querySelector('.list-text');
          let spanWidth;
          let isShowTooltip = false;
          // 获取span元素宽度
          if (span) spanWidth = span.offsetWidth;
          // 判断 el-col的宽度与span的宽度
          if (spanWidth >= colWidth) isShowTooltip = true;

          //当文字超长时
          if (isShowTooltip) {
            const indexArr = keys.split('_');
            const rIndex = indexArr[1],
              cIndex = indexArr[3];
            //找到需要显示 Tooltip组件的元素 赋值true进行显示
            this.pageInfoList[rIndex][cIndex].isShowTooltip = true;
            if (rowWidth && colWidth) {
              // 计算宽度百分比
              let colWidthPercentage = (colWidth / rowWidth).toFixed(8) * 100;
              this.pageInfoList[rIndex][cIndex].width = `calc(${colWidthPercentage}% - 4px)`;
            }
          }
        }
      });
    },

    /******************************************************************
     *initData函数在created中调用,通常pageInfo都能获取到
     *如果获取不到 可以加入watch函数
     ******************************************************************/
    initData(widget) {
      this.colNumber = widget.properties.spans.length || 3;
      if (Array.isArray(widget.properties.spans)) {
        this.spanArr = widget.properties.spans;
      }
      // 过滤-处理数据
      this.getPageInfoList();
      // 获取跳转地址
      this.getJumpRoute(widget.properties);
    },

    // 编辑表格时的展示
    drawInit() {
      if (!this.widget.dataSource?.dataSourceConfig) return;
      const dataSource = this.widget.dataSource?.dataSourceConfig.columnConfig?.customComplexColumns;
      if (dataSource && dataSource.length) {
        this.columnConfigList = dataSource.filter(item => item.display);
        this.defaultFlag = false;
      }
    },

    // 预览的展示
    getPageInfoList() {
      this.limitDataRows = this.widget.properties.dataRows || 10;
      if (this.pageInfo && this.pageInfo.list) {
        let arr = [];
        const list = this.pageInfo.list;
        list.map((item, index) => {
          let newItem = item.columns.filter((sItem, sIndex) => {
            sItem.isShowTooltip = false;
            return sItem.display;
          });
          arr.push(newItem);
        });
        this.pageInfoList = arr.slice(0, this.limitDataRows);
        //
      }
    },

    // 获取跳转地址
    getJumpRoute(properties) {
      this.target = properties.target;
      if (properties.moreLinkUrl) this.moreLinkUrl = properties.moreLinkUrl;
      if (properties.detailLinkUrl) this.detailLinkUrl = properties.detailLinkUrl;
    },

    // 点击更多 路由跳转
    clickMore() {
      if (!this.moreLinkUrl) return;
      let catalogueId = '';
      // 获取路由跳转的参数
      if (this.widget.dataSource.dataSourceConfig.type == 'ARCHIVES_CONFIG') {
        this.pageInfo && this.pageInfo.list[0].columns.map(item => {
          if (item.key == 'catalogue_id') {
            catalogueId = item.value;
          }
        });
      }

      let routeUrl = this.$router.resolve({
        path: this.moreLinkUrl,
        query: { catalogueId: catalogueId }
      });
      window.open(routeUrl.href, this.target);
    },

    // 点击跳转
    clickFileLink(index) {
      if (!this.detailLinkUrl) return;
      let archivesId = '';
      // 获取路由跳转的参数
      if (this.widget.dataSource.dataSourceConfig.type == 'ARCHIVES_CONFIG') {
        this.pageInfo.list[index].columns.map(item => {
          if (item.key == 'id') {
            archivesId = item.value;
          }
        });
      }

      // 跳转 默认打开新窗口
      let routeUrl = this.$router.resolve({
        path: this.detailLinkUrl,
        query: { archivesId: archivesId }
      });
      window.open(routeUrl.href, this.target);
    },

    mouseenterTooltip(e, pIndex, sIndex) {
      if (!e.target.parentNode || !e.target.parentNode.offsetWidth) return;
      if (e.target.offsetWidth >= e.target.parentNode.offsetWidth) {
        this.isShowTooltip[pIndex][sIndex] = true;
        this.$forceUpdate();
      }
    },
    mouseleaveTooltip(pIndex, sIndex) {
      if (this.isShowTooltip[pIndex][sIndex]) {
        this.isShowTooltip[pIndex][sIndex] = false;
        this.$forceUpdate();
      }
    },

    getUuid() {
      const id = SystemUtil.uuid();
      return id;
    }
  }
};
</script>

<style scoped lang="scss">
.el-col-border {
  border-right: 1px dashed black;
  text-align: center;
}
.header {
  position: relative;
  height: 44px;
  line-height: 44px;
  width: 100%;
  letter-spacing: 2px;
  border-bottom: 1px solid #cacaca;
  text-indent: 10px;
  font-size: 20px;
  font-weight: 550;
  color: #0070c1;
  .header-more {
    &:hover {
      color: #409eff;
    }
    cursor: pointer;
    span {
      font-size: 14px;
      i {
        margin-left: -12px;
      }
    }
    color: #aaa;
    position: absolute;
    right: 10px;
    top: 0;
  }
}
.list-con {
  box-sizing: border-box;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  .list-text {
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      color: #1f8cf7;
      text-decoration: underline;
    }
  }
}

/deep/ .el-card__body {
  padding: 0;
}
</style>
