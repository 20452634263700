




















import { WidgetData } from '@/models/form/WidgetModel';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'EleDivider'
})
export default class extends Vue {
  @Prop({
    default: () => {
      return WidgetData;
    }
  })
  widget!: Record<string, any>;
  @Prop({ default: () => {} }) value!: Record<string, any>;
  created() {}
}
