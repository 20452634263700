










































import { Component, Mixins } from 'vue-property-decorator';
import EleSelectorMinix from './selector';
@Component({
  name: 'EleSelect',
  components: { FormDataManager: () => import('@/components/form-data-manager/form-data-manager.vue') }
})
export default class EleSelect extends Mixins(EleSelectorMinix) {
  expandedCodes: string[] = []; //默认展开的父级节点
  created() {
    this.expandedCodes = this.getParentCodes(this.dataValue[0], this.dataSource);
  }
  normalizerHandler(node) {
    let disabled = node.disabled;
    let isDefaultExpanded = false;
    if (this.expandedCodes.includes(node.code)) {
      isDefaultExpanded = true;
    }

    if (node.children) {
      disabled = false;
    }
    return {
      id: node.code,
      label: node.text,
      children: node.children,
      isDisabled: disabled,
      isDefaultExpanded: isDefaultExpanded
    };
  }

  //根据子级code 回溯父级code
  getParentCodes(code: any, data: any) {
    // recallParentCodesFn 回溯的递归函数
    return this.recallParentCodesFn(data, code, []);
  }
  recallParentCodesFn(data: any, code: any, parents: any) {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      // (1)找到id则返回父级code
      if (item.code === code) return parents;
      // (2)判断是否要递归
      if (!item.children || !item.children.length) continue;
      // (3)递归前当前code--插入数组中
      parents.push(item.code);
      //(4)如果递归找到code，则返回父级code
      if (this.recallParentCodesFn(item.children, code, parents).length) return parents;
      // (5)如果递归没找到,删除code
      parents.pop();
    }
    return [];
  }
}
