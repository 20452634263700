import { Component, Prop, Vue, Model, InjectReactive, Watch } from 'vue-property-decorator';
import { WidgetDataSourceConfigTypeEnum, WidgetDataSourceTypeEnum } from '@/models/form/WidgetDataSourceModel';
import { getFormConfig, getWidgetDataSource, getFormDataByWidget } from '@/api/form-design/FormApi';
import { buildFormDataForSave } from '../utils';
@Component({
  name: 'EleSelectorMinix'
})
export default class EleSelectorMinix extends Vue {
  @Model('change') dataValue!: any;

  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: () => {} }) widget!: any;
  @Prop({ default: () => [] }) dataSource!: any;
  @Prop({ default: null }) triggerGroupId!: any;
  @Prop({ default: true }) placeholder: boolean;

  @InjectReactive() flowId: any;
  @InjectReactive() formId: any;
  @InjectReactive() formDataId: any;
  @InjectReactive() formWidgetsValueMap: any;

  formDataDialogVisible: boolean = false; // 表单数据源项管理页面
  curForm: any = {};
  dataSources: any[] = [];
  // 当前名称
  modelName: string = '';
  // 数组名称
  dataValueList: any[] = [];
  //当前节点
  get _activedNode() {
    return this.$store.getters.activedNode;
  }

  /**
   * 是否可以管理选中的数据源项
   */
  get _formDataSourceConfigurable() {
    if (this.widget && this.widget.dataSource) {
      if (this.widget.dataSource.dataSourceConfig) {
        if (this.widget.dataSource.dataSourceConfig.type == WidgetDataSourceConfigTypeEnum.FORM_CONFIG) {
          return this.widget.dataSource.dataSourceConfig.configurable;
        }
      }
    }
    return false;
  }
  mounted() {
    this.setDataSource();
  }

  // 设置数据源
  setDataSource() {
    if (this._formDataSourceConfigurable) {
      let params = {
        formId: this.formId,
        formModelId: this.widget.id,
        page: { pageIndex: 1, pageSize: 20 }
      };
      getFormDataByWidget(params).then((res: any) => {
        if (res.code == '1' && res.data) {
          res.data?.list.sort((a: any, b: any) => {
            return a.columns[2].value - b.columns[2].value;
          });
          let tempArr = [];
          res.data?.list.forEach((item: any) => {
            let obj = {};
            obj['sort'] = item.columns.find((val: any) => {
              return ['序号', '排序'].includes(val.title);
            })?.value;
            obj['code'] = item.columns.find((val: any) => {
              return val.title == '表单数据主键';
            })?.value;
            tempArr.push(obj);
          });
          let arr = this.dataSource;
          arr.forEach((item: any) => {
            tempArr.forEach((value: any) => {
              if (item.code == value.code) {
                item['sort'] = value.sort;
              }
            });
          });
          arr.sort((a: any, b: any) => {
            return a.sort - b.sort;
          });
          this.dataSources = arr;
          if (!this.dataValue && (this.widget.displayName.includes('批示语') || this.widget.displayName.includes('意见'))) {
            if (this.dataSources.length > 0) {
              this.changeValue(this.dataSources[0].code);
            } else {
              this.changeValue('');
            }
          } else {
            if (!this.dataSource.find(item => item.code == this.dataValue)) {
              this.changeValue('');
            }
          }
        } else {
          this.dataSource = [];
          this.$emit('change', '');
        }
      });
    } else {
      this.dataSources = this.dataSource;
    }

    if (!this.widget.properties.multiple) {
      if (this.dataSource.find((item: any) => item.code == this.dataValue)) {
        this.modelName = this.dataSource.find((item: any) => item.code == this.dataValue).text
      }
    } else {
      this.dataValueList = [];
      if (this.dataValue?.length > 0) {
        this.dataValue.forEach(item => {
          let data = this.dataSource.find((sitem: any) => sitem.code == item);
          if (data) {
            this.dataValueList.push(data);
          }
        })
      }
    }
  }

  @Watch('dataSource')
  dataSourceWatcher(val) {
    if (!this._formDataSourceConfigurable) {
      this.dataSources = this.dataSource;
    }
  }
  /**
   * 改变选中值时
   */
  changeValue(val) {
    this.$emit('change', val);
  }

  /**
   * 打开数据源的管理弹出框
   */
  openFormDataDialog() {
    let params = {
      code: this.widget.dataSource.dataSourceConfig.formCode
    };
    getFormConfig(params).then(res => {
      this.curForm = { id: res.data.id, code: res.data.code, belongFormId: this.formId };
      this.formDataDialogVisible = true;
    });
  }

  /**
   * 修改完选项，关闭弹窗时
   */
  handleConfirm() {
    let params: any = {
      formId: this.formId,
      formModelId: this.widget.id,
      triggerGroupId: this.triggerGroupId,
      formModelDataList: buildFormDataForSave(this.formWidgetsValueMap, this.formDataId).filter(a => a.formModelCode)
    };
    if (this._activedNode && this._activedNode.id) {
      params.arguments = {
        var_current_node_id: this._activedNode.id,
        var_process_id: this.flowId
      };
    }
    getWidgetDataSource(params).then((res: any) => {
      if (res.code == '1' && res.data) {
        if (this.widget.dataSource.type == WidgetDataSourceTypeEnum.SIMPLE) {
          let simpleDataSource: any[] = res.data.simpleDataList || [];
          simpleDataSource.forEach(item => {
            if (item.formModelCode == this.widget.compCode) {
              this.$emit('changeDataSource', item.dataList);
              // 更新当前的dataSources以及dataValue
              if (this._formDataSourceConfigurable && !this.disabled) {
                this.dataSources = item.dataList;
                if (item.dataList && item.dataList.length > 0) {
                  if (!item.dataList.find(item => item.code == this.dataValue)) {
                    this.changeValue('');
                  }
                } else {
                  this.changeValue('');
                }
              }
            }
          });
        }
      }
      this.formDataDialogVisible = false;
    });
  }
}
