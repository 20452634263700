<template>
  <el-card
    :style="{
      width: widget.properties.width + '%',
      height: widget.properties.height + 'px'
    }"
  >
    <div
      v-if="widget.properties.showLabel"
      :style="{
        textAlign: getLabelPosition,
        fontSize: widget.properties.labelFontSize + 'px' || '20px',
        fontWeight: widget.properties.labelFontBold == 1 ? 'normal' : 550,
        color: widget.properties.labelFontColor
      }"
      class="header"
    >
      {{ $t(widget.compName) }}
      <div @click="clickMore" class="header-more">
        <span>{{ $t('lang_more') }}<i class="el-icon-d-arrow-right"></i></span>
      </div>
    </div>
    <div class="list-con">
      <div @click="jumpUsuallyApplication(item)" class="item" v-for="(item, index) in iconList" :key="item.id">
        <div
          class="img-con"
          :style="{
            width: widget.properties.iconWidth + 'px',
            height: widget.properties.iconHeight + 'px'
          }"
        >
          <img :src="previewUrl + '?fileId=' + item.icon + '&token=' + token" alt="" />
        </div>
        <p class="item-title">{{ $t(item.name) }}</p>
      </div>
    </div>
  </el-card>
</template>

<script>
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import HomePage from '@/api/home/homePage';
export default {
  name: 'EleApplication',

  data() {
    return {
      token: '',
      loading: false,
      formId: '',
      previewUrl: '/river-fs/file/download',
      // 图片组件上传地址
      target: '_blank',
      iconList: [],
      moreLinkUrl: '/applicationList',
      uploadUrl: '/river-fs/file/create'
    };
  },
  props: {
    widget: {
      type: Object,
      default() {
        return {};
      }
    },
    isDisplay: {
      type: Boolean,
      default: false
    },
    pageInfo: {}
  },
  computed: {
    getLabelPosition() {
      let properties = this.widget.properties;
      if (properties.labelPosition == 'center') return 'center';
      if (properties.labelPosition == 'right') return 'right';
      return 'left';
    }
  },

  created() {
    this.homePageApi = new HomePage();
    this.token = StorageUtil.getSessionStorage('token');
  },
  mounted() {
    if (this.isDisplay) this.initIconList(this.pageInfo);
  },
  methods: {
    imgClick(val) {
      if (val) {
        const properties = this.widget.properties;
        if (properties.target == '_blank') {
          window.open(val.linkUrl, '_blank');
        } else {
          this.$router.push({ path: val.linkUrl });
        }
      }
    },
    initIconList(val) {
      let arr = [];
      if (val && val.list) {
        val.list.map(item => {
          let obj = {};
          item.columns.map(sItem => {
            obj[sItem.key] = sItem.value;
          });
          arr.push(obj);
        });
      }
      this.iconList = arr;
      // this.iconLis
    },

    // 点击更多 路由跳转
    clickMore() {
      if (!this.moreLinkUrl) return;
      let routeUrl = this.$router.resolve({
        path: this.moreLinkUrl,
        query: {
          formModelId: this.widget.id
        }
      });
      window.open(routeUrl.href, this.target);
    },

    // 页面跳转
    jumpUsuallyApplication(val) {
      this.homePageApi.redirectHome({ applicationId: val.id, clientId: process.env.VUE_APP_CLIENT_ID }).then(res => {
        if (res.code == '1') {
          let url = res.data;
          url = url + `&name=${val.name}&icon=${val.icon}`;
          window.open(url, this.target);
        }
      });
      // if (!val.home_page) return;
      // let applicationId = val.id,
      //   name = val.name;
      // if (!applicationId) return;
      // let routeUrl = this.$router.resolve({
      //   path: val.home_page,
      //   query: { applicationId, name }
      // });
      //
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  height: 44px;
  line-height: 44px;
  width: 100%;
  letter-spacing: 2px;
  border-bottom: 1px solid #cacaca;
  text-indent: 10px;
  font-size: 20px;
  font-weight: 550;
  color: #0070c1;
  position: relative;
  .header-more {
    &:hover {
      color: #409eff;
    }
    cursor: pointer;
    span {
      font-size: 14px;
      i {
        margin-left: -12px;
      }
    }
    color: #aaa;
    position: absolute;
    right: 10px;
    top: 0;
  }
}
.list-con {
  margin-top: 20px;
  width: 100%;
  height: calc(100% - 44px);
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-bottom: 10px;
    width: 25%;
    height: auto;
    .item-title {
      width: 100%;
      text-align: center;
    }
    .img-con {
      cursor: pointer;
      width: 60px;
      height: 60px;
      margin: auto;
      box-sizing: border-box;
      padding: 8px;
      background-color: rgba(238, 238, 238, 0.509);
      border-radius: 4px;
      margin-bottom: 4px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
/deep/ .el-card__body {
  padding: 0;
}
</style>
