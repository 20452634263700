











































import { Component, Mixins, Watch } from 'vue-property-decorator';
import EleSelectorMinix from './selector';
@Component({
  name: 'EleCascader',
  components: { FormDataManager: () => import('@/components/form-data-manager/form-data-manager.vue') }
})
export default class EleCascader extends Mixins(EleSelectorMinix) {
  dataValueClone: any[] = [];
  created() {
    this.dataValueClone = this.dataValue;
  }
  @Watch('dataValue')
  dataValueWatcher(val) {
    this.dataValueClone = val;
  }
}
