










































import { Component, Prop, Vue, Watch, InjectReactive } from 'vue-property-decorator';
import FormViewerItem from './form-viewer-item.vue';
import { WidgetModel } from '@/models/form/WidgetModel';
@Component({
  name: 'FormViewerTab',
  components: {
    FormViewerItem
  }
})
export default class extends Vue {
  @InjectReactive() formWidgetsTriggerMap!: any;
  @InjectReactive() formWidgetsDataSourceMap!: any;
  @InjectReactive() formWidgetsRulesMap!: any;
  @InjectReactive() formWidgetsValueMap!: any;

  @Prop({ default: {} }) element!: WidgetModel; // 流程图中使用表单的地方 传过来的表单id
  @Prop({ default: {} }) dataSource: any;

  activeName: string = '';
  tabList: any = [];
  showBorder: boolean = false;
  created() {
    this.initData();
  }

  // 初始化
  initData(): void {
    this.getTabList();
  }

  // 获取tap页数 与tab页信息
  getTabList(): void {
    const components = this.element.properties.components;
    if (components.length) {
      this.activeName = components[0].compCode;
      this.tabList = components;
    } else {
      this.tabList = [];
    }
  }
  handleChangeDataSource({ element, dataSource }) {
    this.$emit('changeDataSource', { element, dataSource });
  }
  // 点击更多
  clickMore() {
    let jumpUrl = '';
    let target = '_blank';
    let widget: any = {};
    this.element.properties.components.map(item => {
      if (item.compCode == this.activeName) {
        jumpUrl = item.properties.moreLinkUrl;
        widget = item;
        return;
      }
    });
    if (jumpUrl) {
      let pageInfo = this.formWidgetsDataSourceMap[this.activeName]; //用来获取catalogueId
      let catalogueId = ''; //跳转后的路由参数
      // 获取路由跳转的参数
      if (widget.dataSource.dataSourceConfig.type == 'ARCHIVES_CONFIG') {
        pageInfo.list[0].columns.map(item => {
          if (item.key == 'catalogue_id') {
            catalogueId = item.value;
          }
        });
      }
      let routeUrl = this.$router.resolve({
        path: jumpUrl,
        query: {
          catalogueId: catalogueId
        }
      });
      window.open(routeUrl.href, target);
    }
  }
}
