var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      style: {
        width: _vm.properties.width + "%",
        height: _vm.properties.height + "px",
        bordeRadius: "0",
      },
    },
    [
      !_vm.isDisplay
        ? _c("div", { staticClass: "header" }, [
            _vm._v(" " + _vm._s(_vm.$t("lang_form_components_tasks")) + " "),
          ])
        : _c(
            "div",
            [
              _c("div", { staticClass: "header" }, [
                _vm._v(" " + _vm._s(_vm.$t("lang_process_center")) + " "),
                _c(
                  "div",
                  { staticClass: "header-more", on: { click: _vm.clickMore } },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("lang_more"))),
                      _c("i", { staticClass: "el-icon-d-arrow-right" }),
                    ]),
                  ]
                ),
              ]),
              _c("FlowCenter", {
                attrs: { isShowMore: false },
                on: { changeActiveName: _vm.changeActiveName },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }